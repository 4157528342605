
import Vue from "vue";

import MortgagesManager from "@/components/MortgagesManager/MortgagesManager.vue";
import {
  quoteMapActions,
  quoteMapGetters,
  quoteMapMutations,
  quoteMapState
} from "@/store/modules/quote";
import { objectDifference, removeEmptyFields } from "@/helpers";
import { atlasfileMapActions } from "@/store/modules/atlasfile";
import { cloneDeep, get, set, isObject } from "lodash";
import { downloadQuoteDocument } from "@/helpers/downloadLossForm";
import { displayXML } from "@/helpers/displayXML";
import OldRatingCustomAlert from "./RatingApplication/OldRatingCustomAlert.vue";
import getQuestions from "@/helpers/underwritingQualifications";
import { isColonial, hasStateWide } from "@/forms/utils";
import { getQuoteDefaults } from "@/helpers/defaultObjectGenerators";
import { authMapGetters, authMapState } from "@/store/modules/auth";
import CoApplicantEditor from "@/views/shared/quotes/RatingApplication/CoApplicantsEditor.vue";
import { generateEditCoApplicantPayload } from "@/helpers/coApplicantsHelper";
import { IQuote, IQuoteApplicant } from "@/store/modules/quote/types";

import {
  isFRMKValidCreditAndLossInfo,
  allowSAFEUPCR
} from "@/helpers/generalHelpers";
import { quoteApplicantForm } from "@/forms/shared/quote.client_information";
import { FormBlock } from "@/components/FormBuilder/types";
import { defaultApplicant } from "@/helpers/defaultObjects";
import UnderwriterInfo from "./Components/UnderwriterInfo.vue";
import { validateYearBuilt } from "@/helpers/validateYearBuilt";
import BackDatingCoverage from "./Components/BackDatingCoverage.vue";
import { stringifyAddress } from "@/helpers/stringifyAddress";

interface IDefaultApplicant {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
  maritalStatus: string;
  employerName: string;
  employerAddress: string;
  ssn: string;
}

interface IApplicantData {
  applicant: IDefaultApplicant;
}

interface IRemarksAndMortgagesData {
  showModal: boolean;
  loading: boolean;
  errors: string[] | string | undefined;
  loadingText: string;
  coApplicantRenderKey: number;
  validationData: Record<string, any>;
  applicantData: IApplicantData;
  taxIdIsValid: boolean;
}
export default Vue.extend({
  name: "remarks-and-mortgages",
  components: {
    MortgagesManager,
    CoApplicantEditor,
    ChangeRequestFileUpload: () =>
      import("@/components/AttachmentUploader/ChangeRequestFileUpload.vue"),
    QuoteSteps: () => import("@/views/shared/quotes/QuoteSteps.vue"),
    CustomAlert: () => import("@/components/CustomAlert/CustomAlert.vue"),
    AgentPickerModal: () =>
      import("@/components/AgentPickerModal/AgentPickerModal.vue"),
    OldRatingCustomAlert,
    UnderwriterInfo,
    BackDatingCoverage
  },
  data(): IRemarksAndMortgagesData {
    return {
      showModal: false,
      loading: false,
      errors: this.$isInEmergencyMaintenanceMode
        ? this.$emergencyMaintenanceModeMessage
        : undefined,
      loadingText: "",
      coApplicantRenderKey: 0,
      validationData: {},
      applicantData: {
        applicant: { ...defaultApplicant }
      },
      taxIdIsValid: true
    };
  },
  mounted() {
    if (this.needsSolarPanelConfirmation) {
      this.$modal.show("hasSolarPanelModal");
    }
  },
  created() {
    this.applicantData.applicant = {
      ...get(this.editing, "quoteApplication.applicant", {})
    };
    this.applicantData = { ...this.applicantData };
  },
  methods: {
    ...quoteMapMutations(["SET_EDIT_FIELD", "SET_EDIT", "SET_ITEM"]),
    ...atlasfileMapActions(["createAtlasfile"]),
    ...quoteMapActions([
      "updateQuote",
      "step9BindQuote",
      "updateQuoteApplication",
      "getSendingOrReceivingXML",
      "step9saveRemarksAndMortgages",
      "saveQuotePropertyFields"
    ]),
    async confirmYesSolarPanel(): Promise<void> {
      await this.confirmSolarPanel("Yes");
    },
    async confirmNoSolarPanel(): Promise<void> {
      await this.confirmSolarPanel("No");
    },
    async confirmSolarPanel(event: "Yes" | "No") {
      try {
        this.otherFieldChangeHandler({
          key: "hasConfirmedSolarPanel",
          value: {
            status: true,
            answer: event
          }
        });
      } catch (error) {
        this.errors = error.message;
        this.$bugSnagClient.notify(error);
        console.log(error.message);
      } finally {
        this.$modal.hide("hasSolarPanelModal");
      }
    },
    hasValidatedYearBuilt(): boolean {
      const {
        success = true,
        reason = "",
        message = ""
      } = validateYearBuilt(this.editing.yearBuild, [
        this.editing.ratingSelectedData.companyNumber
      ]);
      if (
        !success &&
        (!this.editing.approvalCode || !this.editing.hasUnderwriterApproved)
      ) {
        this.errors = [`Can't bind. ${reason}. ${message}`];
        return false;
      }
      return true;
    },
    formFieldChangedHandler(data: {
      key: string;
      value: string | Record<string, any>;
    }) {
      const { key, value } = data;
      if (key === "applicant.ssn") {
        if (isObject(value)) {
          this.taxIdIsValid = value.isValid;
          set(this.applicantData, key, value.value);
        } else if (typeof value === "string") {
          set(this.applicantData, key, value);
        }
      } else {
        set(
          this.applicantData,
          key,
          key == "applicant.lastName" ||
            key == "applicant.middleName" ||
            key == "applicant.firstName"
            ? value.trim().toUpperCase()
            : value
        );
      }
      this.applicantData = { ...this.applicantData };
    },
    async saveStep9(): Promise<void> {
      if (!this.updatedFields || Object.keys(this.updatedFields).length === 0)
        return;
      try {
        this.loading = true;
        this.loadingText = "Saving data. Please wait...";
        const response = await this.step9saveRemarksAndMortgages({
          payload: this.updatedFields,
          id: this.$route.params.quoteId
        });
        this.SET_ITEM(response.data);
        this.SET_EDIT(response.data);
      } catch (error) {
        const message = (error as any).message || "";
        this.errors = message;
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    async updateQuoteUser(newUser: any): Promise<void> {
      try {
        this.loading = true;
        this.loadingText = "Saving data. Please wait...";
        let fieldsToUpdate: {
          createdBy: string[];
          originalCreator?: string;
        } = {
          createdBy: newUser._id
        };
        //saving originalCreator once so we'll get the id of the first person who created the quote
        if ("originalCreator" in this.editing === false) {
          fieldsToUpdate = Object.assign(fieldsToUpdate, {
            originalCreator: this.editing.createdBy
          });
        }
        const response = await this.updateQuote({
          id: this.$route.params.quoteId,
          update: fieldsToUpdate
        });
        if (response && response.data) {
          this.SET_ITEM(response.data);
          this.SET_EDIT(response.data);
        }
        this.$appNotifySuccess("Quote updated successfully with new agent");
      } catch (error) {
        const message = (error as any).message || "";
        this.errors = message;
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
        this.$modal.hide("selectUserModal");
      }
    },
    async bindSuccessHandler(): Promise<void> {
      this.$modal.hide("bindSuccessModal");
      await this.printQuote();
      this.$router.push("/quotes");
    },
    async printQuote(): Promise<void> {
      try {
        this.loading = true;
        this.loadingText = "Printing document. Please wait...";
        await downloadQuoteDocument({
          quoteIds: [this.editing._id],
          rateId: this.editing.ratingSelected
        });
      } catch (error) {
        const message = (error as any).message || "";
        this.errors = message;
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    async bindQuote(): Promise<void> {
      try {
        this.loading = true;
        this.loadingText = "Binding quote. Please wait...";
        const response = await this.step9BindQuote(this.$route.params.quoteId);
        if (response && response.data) {
          this.SET_EDIT(response.data);
          this.$modal.show("bindSuccessModal");
        } else {
          (this.errors as any) = "Error binding quote. Try again later.";
        }
      } catch (error) {
        const message = (error as any).message || "";
        this.errors = message;
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    otherFieldChangeHandler({ key, value }: any): void {
      this.SET_EDIT_FIELD({ key, value });
      this.saveStep9();
    },
    addMortgageHandler(mortgage: any): void {
      const mortgages = [...this.editing.mortgages];
      const index = mortgages.map((m: any) => m._id).indexOf(mortgage._id);
      if (index !== -1 && mortgage._id) {
        mortgages.splice(index, 1, mortgage);
      } else {
        mortgages.push(mortgage);
      }
      this.SET_EDIT_FIELD({ key: "mortgages", value: mortgages });
      this.saveStep9();
    },
    deleteMortgageHandler(mortgage: any): void {
      let mortgages = [];
      if (mortgage && mortgage._id) {
        mortgages = this.editing.mortgages.filter(
          (mortg: any) => mortg._id !== mortgage._id
        );
      } else {
        mortgages = this.editing.mortgages.filter(
          (mortg: any) => mortg.mortgageName !== mortgage.mortgageName
        );
      }
      this.SET_EDIT_FIELD({ key: "mortgages", value: mortgages });
      this.saveStep9();
    },
    async attachmentUploadHandler(upload: any) {
      this.loading = true;
      try {
        let response = await this.createAtlasfile(this.createFormData(upload));
        this.mapFileData(response);
      } catch (error) {
        this.$appNotifyError(error.message);
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
      }
    },
    deleteAttachmentHandler(response: any): void {
      let attachments = [];
      if (response.length) {
        let mapKeys = response.map((file: any) => file.id);
        attachments = mapKeys;
      } else {
        attachments.push(response.id);
      }
      this.SET_EDIT_FIELD({ key: "attachments", value: attachments });
      this.saveStep9();
    },
    onToolBarItemClick(event: string) {
      switch (event) {
        case "bind":
          this.bindQuote();
          break;
        case "quote-print":
          this.printQuote();
          break;
        case "quote-return":
          this.$modal.show("returnToQuoteModal");
          break;
        case "VS":
          this.sendingXMLHandler("VS");
          break;
        case "VR":
          this.sendingXMLHandler("VR");
          break;
      }
    },
    mapFileData(response: any) {
      const attachments = cloneDeep(this.editing.attachments);
      if (response.length) {
        let mapKeys = response.map((file: any) => file.id);
        attachments.push(...mapKeys);
      } else {
        attachments.push(response.id);
      }
      this.SET_EDIT_FIELD({ key: "attachments", value: attachments });
      this.saveStep9();
    },
    createFormData(files: File[]) {
      const formData = new FormData();
      files.forEach((file: File) => formData.append(file.name, file));
      formData.append("type", "attachment");
      formData.append("isPublic", "false");
      const agencyId = get(this.editing, "createdByData.agencyId");
      if (agencyId) {
        formData.append(
          "documentAccessConfig",
          JSON.stringify({ allowedAgencies: [agencyId] })
        );
      }

      return formData;
    },
    openSelectAgentModal() {
      this.$modal.show("selectUserModal");
    },
    async sendingXMLHandler(responseType: "VS" | "VR"): Promise<void> {
      try {
        const response = await this.getSendingOrReceivingXML({
          rateId: this.editing.ratingSelected,
          responseType,
          isBinding: true
        });
        const { rawXML } = response.data;
        displayXML(rawXML);
      } catch (error) {
        this.errors = (error as Error).message || "";
        this.$bugSnagClient.notify(error);
      }
    },
    async addCoApplicant(coApplicant: Partial<IQuoteApplicant>) {
      const payload = removeEmptyFields(coApplicant);
      const body = {
        coApplicants: payload
      };
      await this.updateQuoteApplicationData(body);
    },
    async editCoApplicant(data: {
      index: number;
      coApplicant: Partial<IQuoteApplicant>;
    }) {
      const existing = get(this.editing, "quoteApplication.coApplicants", []);
      const payload = generateEditCoApplicantPayload(data, existing);

      await this.updateQuoteApplicationData(payload);
    },
    async deleteCoApplicant(index: number) {
      const existing = get(this.editing, "quoteApplication.coApplicants", []);
      const applicantToDelete = existing[index];
      const payload = {
        coApplicants: { _id: applicantToDelete._id, delete: true }
      };
      await this.updateQuoteApplicationData(payload);
    },
    async updateQuoteApplicationData(updates: any): Promise<void> {
      if (this.loading) {
        return;
      }
      try {
        let response;
        const quoteId = this.$route.params.quoteId;
        this.loading = true;
        if (updates) {
          response = await this.updateQuoteApplication({
            quoteId,
            updates
          });
        }
        this.SET_ITEM(response.data);
        this.SET_EDIT(response.data);
      } catch (error) {
        this.$appNotifyError(
          "There was an error saving your responses. Try again later."
        );
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
      }
    },
    async saveApplicantDataOnBlur() {
      if (
        !this.updatedApplicantFields ||
        !Object.keys(this.updatedApplicantFields).length
      ) {
        return;
      }
      if (!this.validateApplicantFields()) return;

      await this.updateQuoteApplicationData({
        applicant: this.updatedApplicantFields
      });

      this.applicantData.applicant = cloneDeep(
        this.editing.quoteApplication.applicant
      );
    },
    validateApplicantFields(): boolean {
      const payload = { applicant: this.updatedApplicantFields };
      const isValid =
        this.validationData.fieldsWithErrors.every((field: any) => {
          return !get(payload, field);
        }) &&
        (!get(payload, "applicant.ssn") || this.taxIdIsValid);
      return isValid;
    }
  },

  computed: {
    ...quoteMapState(["makingApiRequest", "editing"]),
    ...quoteMapGetters(["getQuoteById"]),
    ...authMapState(["initData"]),
    ...authMapGetters(["getCompanyByCompanyNumber"]),
    hasValidAgencyCode(): boolean {
      if (this.$isCurrentUserAdmin) return true;
      return (
        this.editing &&
        this.editing?.ratingValidations?.hasValidAgencyCode === true
      );
    },
    needsSolarPanelConfirmation(): boolean {
      const solarPanel = get(
        this.editing,
        "propertyAnalytics.capeDataResponse.property_features.roof_solar_panel",
        ""
      );

      const confirmedSolar = get(this.editing, "hasConfirmedSolarPanel.status");
      const confirmedSolarAnswer = get(
        this.editing,
        "hasConfirmedSolarPanel.answer"
      );

      return (
        solarPanel === "with_solar_panel" &&
        confirmedSolar === false &&
        !confirmedSolarAnswer
      );
    },
    solarModalDescription(): string {
      return `Please confirm that the property at ${stringifyAddress(
        this.editing.riskAddress
      )} does have solar
       panels?`;
    },
    pickerOptions() {
      return {
        disabledDate: () => false
      };
    },
    hasValidMortgageAsBillingMortgagee(): Record<string, any> {
      return get(
        this.editing,
        "ratingValidations.hasValidMortgageAsBillingMortgagee"
      );
    },
    updatedFields(): Record<string, any> {
      return objectDifference(this.editing, this.original);
    },
    updatedApplicantFields(): Record<string, any> {
      return objectDifference(
        this.applicantData.applicant,
        this.editing.quoteApplication.applicant
      );
    },
    original(): IQuote {
      return this.getQuoteById(this.$route.params.quoteId);
    },
    policyNumber(): string | undefined {
      if (this.editing && this.editing.bindingResponse) {
        return this.editing.bindingResponse.policyNumber;
      }
      return undefined;
    },
    capeDataMessage(): string {
      if (this.policyNumber) {
        return get(
          this.editing,
          "propertyAnalytics.capeDataEvaluationResponse.msg"
        );
      }
      return "";
    },
    formIsValid(): boolean {
      const criteria = [
        !!(
          this.editing &&
          this.editing.mortgages &&
          this.editing.mortgages.length > 0
        )
      ];
      if (this.editing && this.editing.priorCoverage) {
        const {
          priorPolicyNumber,
          priorPolicyExpirationDate,
          priorCarrier
        } = this.editing;
        criteria.push(
          ...[!!priorPolicyNumber, !!priorPolicyExpirationDate, !!priorCarrier]
        );
      }
      return criteria.every((criterion: boolean) => criterion); //very terse
    },
    validInsuredNames(): boolean {
      const insuredFirstName = get(
        this.editing,
        "quoteApplication.applicant.firstName",
        ""
      );
      const insuredLastName = get(
        this.editing,
        "quoteApplication.applicant.lastName",
        ""
      );
      return insuredFirstName && insuredLastName;
    },
    canBind(): boolean {
      return !!(
        this.hasRatings &&
        this.hasValidAgencyCode &&
        this.validInsuredNames &&
        !this.hasProhibitedCounty &&
        this.editing.ratingSelected &&
        this.hasValidatedYearBuilt() &&
        this.editing.ratingValidations &&
        !this.needsSolarPanelConfirmation &&
        !this.isInEmergencyMaintenanceMode &&
        this.editing.ratingValidations?.validEffectiveDate &&
        this.editing.ratingValidations?.hasTerritoryInformation &&
        this.editing.ratingValidations?.hasValidRemarksAndMortgages &&
        this.editing.ratingValidations?.ratingCompanyIsEnabledForUser &&
        this.editing.ratingValidations.hasValidMortgageAsBillingMortgagee
      );
    },
    isBound(): boolean {
      return !!this.policyNumber && !this.errors;
    },
    quoteHasBeenDeleted(): boolean {
      return !!(this.editing && this.editing.deleted);
    },
    printMessage(): string {
      return "Print Application";
    },
    topButtonSecondaryItems(): {
      text: string;
      key: string;
      subItems: { title: string; command: string }[];
    } | null {
      const options = [
        {
          title: this.printMessage,
          command: "quote-print"
        },
        {
          title: "Back To Quote",
          command: "quote-return"
        }
      ];
      if (this.$isCurrentUserAdmin) {
        options.push(
          {
            title: "View Sending XML",
            command: "VS"
          },
          {
            title: "View Receiving XML",
            command: "VR"
          }
        );
      }

      return {
        text: "Actions",
        key: "actions",
        subItems: options
      };
    },
    hasRatings(): boolean {
      return (
        this.editing &&
        this.editing.ratingValidations &&
        this.editing.ratingValidations.hasSelectedRating
      );
    },
    answeredQuestions(): Record<string, any> {
      const defaultQuote = getQuoteDefaults({});
      const updatesFromApi = objectDifference(this.original, defaultQuote);
      return updatesFromApi.underwritingResponses || {};
    },
    hasValidApplicantAndCoApplicants(): boolean {
      return this.editing.ratingValidations.hasValidApplicantAndCoApplicants;
    },
    hasValidUnderWritingResponses(): boolean {
      return this.editing?.hasValidUnderWritingResponses;
    },
    underwritingQuestionsForQuote(): any[] {
      let quote = this.editing;
      const underWriterApprovalCode = get(
        quote,
        "underwritingResponses.underWriterApprovalCode"
      );
      const policyType = get(quote, "policyType", "");
      let getCompanyQuestions = getQuestions(
        this.companyNumber as number,
        policyType,
        this.isStateWide,
        underWriterApprovalCode
      );
      return getCompanyQuestions;
    },
    companyNumber(): number | null {
      if (this.editing && this.editing?.ratingSelectedData) {
        return this.editing.ratingSelectedData.companyNumber;
      }
      return null;
    },
    isColonial(): boolean {
      if (this.companyNumber) return isColonial([this.companyNumber]);
      return false;
    },
    isStateWide(): boolean {
      if (this.companyNumber) return hasStateWide([this.companyNumber]);
      return false;
    },
    hasProhibitedCounty(): boolean {
      let isProhibited = false;
      if (this.editing) {
        isProhibited = !!this.editing.hasProhibitedCounty;
      }
      return isProhibited;
    },
    selectedRatingCompanyName(): string | null {
      const { companyNumber } = this;
      if (!companyNumber) return null;
      const ratingCompany = this.getCompanyByCompanyNumber(companyNumber);
      return ratingCompany?.companyName || null;
    },
    isInEmergencyMaintenanceMode(): boolean {
      return get(this.initData, "isInEmergencyMaintenanceMode", false);
    },
    emergencyMaintenanceModeMessage(): string {
      return get(this.initData, "emergencyMaintenanceModeMessage", "");
    },
    applicantIsMarried(): boolean {
      return (
        get(this.editing, "quoteApplication.applicant.maritalStatus", "") ===
        "married"
      );
    },
    coApplicantsExist(): boolean {
      return !!get(this.editing, "quoteApplication.coApplicants", []).length;
    },
    showApplicantForm(): boolean {
      return (
        isFRMKValidCreditAndLossInfo(this.editing) ||
        allowSAFEUPCR(this.editing)
      );
    },
    quoteApplicantSchema(): FormBlock[] {
      return quoteApplicantForm(this.applicantIsMarried);
    },
    quoteId(): string {
      return this.$route.params.quoteId;
    }
  }
});
