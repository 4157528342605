
import Vue from "vue";
import { elementIsInViewPort } from "@/helpers";
export default Vue.extend({
  name: "backdating-coverage",
  components: {
    CustomAlert: () => import("@/components/CustomAlert/CustomAlert.vue")
  },
  props: {
    link: String,
    hasValidEffectiveDate: {
      required: true,
      type: Boolean
    },
    inputRef: {
      type: Object,
      required: false
    }
  },
  methods: {
    focusRiskAddressInput() {
      const parentElement = this.inputRef.$el;
      if (parentElement) {
        const scrollToElement = parentElement.querySelector(
          "#field_field_houseNumber"
        );
        const effectiveDate = parentElement.querySelector(
          "#field_field_effectiveDate input"
        );
        scrollToElement.scrollIntoView({
          behavior: "smooth",
          block: "end"
        });
        elementIsInViewPort(
          scrollToElement,
          function() {
            effectiveDate.focus();
          },
          {
            rootMargin: "-20px"
          }
        );
      }
    }
  }
});
