
/**
 * Helps add and remove, edit mortgages
 * display mortgages
 */
import Vue from "vue";
import MortgagesEditor from "@/views/shared/quotes/Components/MortgageEditor.vue";
import CustomMortgageEditor from "@/views/shared/quotes/Components/CustomMortgageEditor.vue";
import MortgageCompanyPickerModal from "@/components/MortgageCompanyPicker/MortgageCompanyPickerModal.vue";

export default Vue.extend({
  name: "mortgages-manager",
  components: {
    MortgagesEditor,
    MortgageCompanyPickerModal,
    CustomMortgageEditor
  },
  props: {
    isRequired: {
      type: Boolean,
      default: false,
      required: false
    },
    mortgages: {
      type: Array,
      required: true,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      showMortgagePicker: false,
      editorKey: 0,
      showCustomEditor: false
    };
  },
  methods: {
    tableRowClickedHandler(mortgage: any) {
      if (mortgage.mortgageName !== "OTHER") {
        const existingMortgage = this.mortgages.find(
          (mort: any) => mortgage._id === mort._id
        );
        if (!existingMortgage) {
          this.$emit("add-mortgage", mortgage);
          this.$modal.hide("selectMortgageModal");
          this.editorKey = this.editorKey + 1;
        }
      } else {
        this.$modal.hide("selectMortgageModal");
        this.showCustomEditor = true;
      }
    }
  }
});
