import {
  useFormFieldGroup,
  useSelectField,
  useTextField
} from "@/components/FormBuilder/Helpers";
import { FormBlock } from "@/components/FormBuilder/types";
import * as selectOptions from "@/helpers/selectOptions";

export const customMortgageForm: FormBlock[] = [
  ...useFormFieldGroup(
    [
      useTextField({
        key: "mortgageName",
        label: "Mortgage Name",
        placeholder: "Name",
        required: true
      }),
      useTextField({
        key: "loanNumber",
        label: "Loan Number",
        placeholder: "Loan Number",
        validations: [
          {
            method: "minLength",
            value: 2,
            error: "Loan Number must have at least two numbers"
          }
          // {
          //   method: "integer",
          //   error: "Loan Number must be numeric only"
          // }
        ],
        required: false
      })
    ],
    { layout: "2-col" }
  ),
  ...useFormFieldGroup(
    [
      useTextField({
        key: "city",
        label: "City",
        placeholder: "City",
        required: true
      }),
      useSelectField({
        key: "state",
        label: ["fields", "stateLabel"],
        required: true,
        options: selectOptions.states,
        filterable: true,
        allowFirstOption: true
      }),
      useTextField({
        key: "zipCode",
        label: "Zip Code",
        placeholder: "Zip Code",
        required: true,
        validations: [
          {
            method: "minLength",
            value: 4,
            error: "Zip code must have at least four numbers"
          },
          {
            method: "integer",
            error: "Zip code must be numeric only"
          }
        ]
      })
    ],
    { layout: "3-col" }
  ),
  ...useFormFieldGroup(
    [
      useTextField({
        key: "address",
        label: "Address",
        placeholder: "Address",
        required: true
      })
    ],
    { layout: "2-col" }
  )
];
