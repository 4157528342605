
import Vue from "vue";
import { customMortgageForm } from "@/forms/shared/mortgages";
import { FormBlock } from "@/components/FormBuilder/types";
export default Vue.extend({
  name: "mortgage-editor",
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      mortgageData: {
        address: "",
        city: "",
        mortgageName: "",
        shortName: "",
        state: "",
        zipCode: "",
        loanNumber: ""
      },
      validationData: {}
    };
  },
  computed: {
    mortgageFields(): FormBlock[] {
      return customMortgageForm;
    }
  },
  methods: {
    formFieldChangedHandler({ key, value }: { key: any; value: any }): void {
      (this.mortgageData as any)[key] = value;
    },
    addMortgage() {
      this.$emit("add-mortgage", this.mortgageData);
      this.$emit("change-visibility", false);
    }
  }
});
